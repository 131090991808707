import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { useRouter } from 'next/router'
import { _convertCMSFile } from '@/helper/manageParameter'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { useHookCheckTranslateLink } from '@/helper/useTranslateLink'
import { useHookProduct } from '@/component/hooks/MainContext'
import { formatListWithNumberOrder } from '@/helper/formatNumber'
import { localeDataPackage } from '@/content/translate/locale-data'

SwiperCore.use([Pagination])

const configGeneral = {
   initialSlide: 1,
   spaceBetween: 20,
   pagination: { clickable: true },
   autoHeight: true,
}

export const SlidePageHomeMyProduct = ({
   products = [],
   isTitleImage = false,
}) => {
   const { __handleSwitchLang } = useHookCheckTranslateLink()

   return (
      <Swiper
         // initialSlide={1}
         spaceBetween={20}
         pagination={{ clickable: true }}
         autoHeight={true}
         breakpoints={{
            640: {
               slidesPerView: 2,
               spaceBetween: 20,
               centeredSlides: false,
               loopAdditionalSlides: 0,
            },
            768: {
               slidesPerView: 2,
               spaceBetween: 20,
               centeredSlides: false,
               loopAdditionalSlides: 0,
            },
            1024: {
               slidesPerView: 2.5,
               spaceBetween: 20,
               centeredSlides: true,
               loopAdditionalSlides: 100,
            },
         }}
         speed={1000}
         loop={true}
         centeredSlides={true}
         loopAdditionalSlides={100}
         modules={[Pagination]}
         className={'my-swiper-package '}
         mousewheel={true}>
         {products.map((pkg, index) => {
            const number = index + 1
            const infoType = pkg.type || {}

            const _getLogo = (passNumber) => {
               return infoType.logos[passNumber]
            }

            console.log('pkg type: ', infoType)

            return (
               <SwiperSlide
                  key={index}
                  onClick={() => __handleSwitchLang(pkg.linkDetail)}
                  role="button"
                  className="card-package target-circle-primary d-flex flex-column with-primary-brand cursor-pointer">
                  <h3 className="mb-0 product-title">
                     {isTitleImage &&
                     !_.isEmpty(pkg.type) &&
                     !_.isEmpty(pkg.type.logos) ? (
                        <span className="d-inline-flex">
                           <img
                              src={_convertCMSFile(
                                 _getLogo(infoType.info === 'Lite Plus' ? 2 : 0)
                                    .url
                              )}
                              alt="GlobalXtreme Product Lite"
                              className={
                                 infoType.info === 'Xtreme Lite'
                                    ? 'w-logo-product'
                                    : infoType.info === 'Signature'
                                    ? 'w-logo-product-signature'
                                    : ''
                              }
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />
                        </span>
                     ) : (
                        pkg.title
                     )}

                     <sup className="ms-2">
                        {number > 9 ? number : '0' + number}
                     </sup>
                  </h3>
                  {pkg.sub_title && (
                     <span className="d-block with-color-primary sub-title">
                        {pkg.sub_title}
                     </span>
                  )}
                  <div
                     className={
                        'row align-items-center mt-auto ' +
                        (pkg.sub_title ? '' : 'priority-services')
                     }>
                     <div className="col-10 col-md-9">
                        <div className="row align-items-center">
                           <div className="col-auto">
                              <h2 className="mb-0 product-speed">
                                 {pkg.speed}
                              </h2>
                           </div>
                           <div className="col">
                              <p className="mb-0 product-unit">{pkg.unit}</p>
                              {pkg.unit_sub ? (
                                 <p className="mb-0 product-sub-unit">
                                    {pkg.unit_sub}
                                 </p>
                              ) : null}
                           </div>
                        </div>
                     </div>
                     <div className="col-2 col-md-3 d-flex justify-content-end icon-arrow">
                        <ArrowUpRightIcon
                           strokeWidth={1.5}
                           viewBox="0 0 20 22"
                           className="text-black"
                        />
                     </div>
                  </div>
               </SwiperSlide>
            )
         })}
      </Swiper>
   )
}

export const SlideProductTypeBlack = ({
   products = [],
   isDark = false,
   extraClass = '',
   initialSlide = 0,
   isTitleImage = false,
}) => {
   const { __handleSwitchLang } = useHookCheckTranslateLink()

   return (
      <Swiper
         initialSlide={initialSlide}
         spaceBetween={20}
         pagination={{ clickable: true }}
         autoHeight={true}
         breakpoints={{
            425: {
               slidesPerView: 1,
               spaceBetween: 20,
               centeredSlides: false,
               loopAdditionalSlides: 0,
            },
            640: {
               slidesPerView: 2,
               spaceBetween: 20,
               centeredSlides: false,
               loopAdditionalSlides: 0,
            },
            768: {
               slidesPerView: 2,
               spaceBetween: 20,
               centeredSlides: false,
               loopAdditionalSlides: 0,
            },
            1024: {
               slidesPerView: 2.5,
               spaceBetween: 20,
               centeredSlides: true,
               loopAdditionalSlides: 100,
            },
            1400: {
               slidesPerView: 2.5,
               spaceBetween: 20,
               // centeredSlides: true,
               loopAdditionalSlides: 100,
               modules: Pagination,
               pagination: { clickable: true },
            },
         }}
         speed={1000}
         loop={true}
         // centeredSlides={true}
         loopAdditionalSlides={100}
         modules={[Pagination]}
         className={
            'my-swiper-package ' + extraClass + (isDark ? ' with-bg-dark' : '')
         }
         mousewheel={true}>
         {products.map((pkg, index) => {
            const number = index + 1

            const _getLogo = (passNumber) => {
               return pkg.type.logos[passNumber]
            }

            return (
               <SwiperSlide
                  key={index}
                  onClick={() => __handleSwitchLang(pkg.linkDetail)}
                  className={
                     'card-package with-primary-brand d-flex flex-column min-height-350 target-circle-primary cursor-pointer ' +
                     (isDark ? 'with-bg-dark' : '')
                  }>
                  <h3 className="mb-0 product-title">
                     {isTitleImage && !_.isEmpty(pkg.type.logos) ? (
                        <span className="d-inline-flex">
                           <img
                              src={_convertCMSFile(_getLogo(0).url)}
                              alt="GlobalXtreme Product Signature"
                              className="pkg-logo-active"
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />

                           <img
                              src={_convertCMSFile(_getLogo(1).url)}
                              alt="GlobalXtreme Product Signature active"
                              className="pkg-logo-no-active"
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />
                        </span>
                     ) : (
                        pkg.title
                     )}
                     <sup className="ms-2">
                        {number > 9 ? number : '0' + number}
                     </sup>
                  </h3>
                  {pkg.sub_title && (
                     <span className="d-block with-color-primary product-sub-title">
                        {pkg.sub_title}
                     </span>
                  )}

                  <div className="wp-product-footer border-top pt-4 mt-auto card-footer border-dark-400">
                     <div
                        className={
                           'row align-items-center ' +
                           (pkg.sub_title ? '' : 'priority-services')
                        }>
                        <div className="col-10 col-md-9">
                           <div className="row align-items-center">
                              <div className="col-auto">
                                 <h2 className="mb-0 product-speed">
                                    {pkg.speed}
                                 </h2>
                              </div>
                              <div className="col">
                                 <p className="mb-0 product-unit h5">
                                    {pkg.unit}
                                 </p>
                                 {pkg.unit_sub ? (
                                    <p className="mb-0 text-priority product-sub-unit">
                                       {pkg.unit_sub}
                                    </p>
                                 ) : null}
                              </div>
                           </div>
                        </div>
                        <div className="col-2 col-md-3 d-flex justify-content-end product-link-icon">
                           <ArrowUpRightIcon
                              strokeWidth={1.5}
                              viewBox="0 0 20 22"
                              className="text-black"
                           />
                        </div>
                     </div>
                  </div>
               </SwiperSlide>
            )
         })}
      </Swiper>
   )
}

export const SliderProductXLite = ({
   products = [],
   extraClass = '',
   slidesPerView = 2,
   config = {},
   isDarkBrand = false,
   isTitle = true,
}) => {
   const { __handleSwitchLang } = useHookCheckTranslateLink()

   return (
      <Swiper
         // initialSlide={1}
         spaceBetween={20}
         pagination={{ clickable: true }}
         autoHeight={true}
         autoplay
         slidesPerView={slidesPerView}
         speed={1000}
         loopAdditionalSlides={100}
         modules={[Pagination]}
         className={'my-swiper-package ' + extraClass}
         mousewheel={true}
         {...config}>
         {!_.isEmpty(products)
            ? products.map((pkg, index) => {
                 const number = index + 1

                 const _getLogo = (passNumber) => {
                    return pkg.type.logos[passNumber]
                 }

                 return (
                    <SwiperSlide
                       key={index}
                       onClick={() => __handleSwitchLang(pkg.linkDetail)}
                       className={
                          'card-package target-circle-primary d-flex flex-column cursor-pointer ' +
                          (isDarkBrand
                             ? ' with-dark-brand'
                             : 'with-primary-brand')
                       }>
                       <h3 className="mb-0 product-title">
                          {isTitle ? (
                             pkg.title
                          ) : !_.isEmpty(pkg.type.logos) ? (
                             <span className="d-inline-flex">
                                <img
                                   src={_convertCMSFile(_getLogo(0).url)}
                                   alt="GlobalXtreme Product Lite"
                                   className="w-logo-product"
                                   width="auto"
                                   height="auto"
                                   loading="lazy"
                                />

                                {/*<img*/}
                                {/*   src={_convertCMSFile(_getLogo(1).url)}*/}
                                {/*   alt="GlobalXtreme Product Lite Plus active"*/}
                                {/*   className="pkg-logo-active"*/}
                                {/*   width="auto"*/}
                                {/*   height="auto"*/}
                                {/*   loading="lazy"*/}
                                {/*/>*/}
                             </span>
                          ) : (
                             pkg.title
                          )}
                          <sup className="ms-2">
                             {number > 9 ? number : '0' + number}
                          </sup>
                       </h3>
                       {pkg.sub_title && (
                          <span className="d-block with-color-primary">
                             {pkg.sub_title}
                          </span>
                       )}
                       <div
                          className={
                             'row align-items-center mt-auto '
                             // + (pkg.sub_title ? '' : 'priority-services')
                          }>
                          <div className="col-10 col-md-9">
                             <div className="row align-items-center">
                                <div className="col-auto">
                                   <h2 className="mb-0 product-speed">
                                      {pkg.speed}
                                   </h2>
                                </div>
                                <div className="col">
                                   <p className="mb-0 product-unit">
                                      {pkg.unit}
                                   </p>
                                   {pkg.unit_sub ? (
                                      <p className="mb-0 product-sub-unit">
                                         {pkg.unit_sub}
                                      </p>
                                   ) : null}
                                </div>
                             </div>
                          </div>
                          <div className="col-2 col-md-3 d-flex justify-content-end">
                             <ArrowUpRightIcon
                                strokeWidth={1.5}
                                viewBox="0 0 20 22"
                                className="text-black icon-svg"
                             />
                          </div>
                       </div>
                    </SwiperSlide>
                 )
              })
            : null}
      </Swiper>
   )
}

export const SlideProductXLiteInDetail = ({
   products = [],
   extraClass = '',
   isTermAndCond = true,
   isArrowIcon = false,
   isBackgroundDark = false,
   isActiveDark = false,
   isRequired,
   isFooter = false,
   slidesPerView = 2,
   isTitle = true,
   config = {},
}) => {
   const router = useRouter()
   const { __handleSwitchLang } = useHookCheckTranslateLink()
   const useProduct = useHookProduct()

   return (
      <Swiper
         // initialSlide={1}
         spaceBetween={20}
         pagination={{ clickable: true }}
         autoHeight={true}
         autoplay
         slidesPerView={slidesPerView}
         speed={1000}
         // loop={true}
         // centeredSlides={true}
         loopAdditionalSlides={100}
         modules={[Pagination]}
         className={'my-swiper-package ' + extraClass}
         mousewheel={true}
         {...config}>
         {products.map((pkg, index) => {
            const number = index + 1

            const _getLogo = (passNumber) => {
               return pkg.type.logos[passNumber]
            }

            return (
               <SwiperSlide
                  key={index}
                  className={
                     'card-package card-product d-flex flex-column min-height-375 target-circle-primary with-primary-brand cursor-pointer ' +
                     (isBackgroundDark ? 'swiper-slide-dark' : '')
                  }
                  onClick={() => {
                     useProduct.choose(formatListWithNumberOrder(pkg, index))
                     router.push('/coverage-area')
                  }}>
                  <h3 className="mb-0 product-title">
                     {/*{pkg.title}*/}

                     {isTitle ? (
                        pkg.title
                     ) : !_.isEmpty(pkg.type.logos) ? (
                        <span className="d-inline-flex">
                           <img
                              src={_convertCMSFile(_getLogo(0).url)}
                              alt="GlobalXtreme Product Lite"
                              className="w-logo-product"
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />

                           {/*<img*/}
                           {/*   src={_convertCMSFile(_getLogo(1).url)}*/}
                           {/*   alt="GlobalXtreme Product Lite Plus active"*/}
                           {/*   className="pkg-logo-active"*/}
                           {/*   width="auto"*/}
                           {/*   height="auto"*/}
                           {/*   loading="lazy"*/}
                           {/*/>*/}
                        </span>
                     ) : (
                        pkg.title
                     )}

                     <sup className="ms-2">
                        {number > 9 ? number : '0' + number}
                     </sup>
                  </h3>
                  {pkg.sub_title && (
                     <span className="d-block with-color-primary">
                        {pkg.sub_title}
                     </span>
                  )}

                  <div
                     className={[
                        'row align-items-center mt-auto',
                        // pkg.priority ? '' : 'priority-services',
                     ].join(' ')}>
                     <div
                        className={!isArrowIcon ? 'col-12' : 'col-10 col-md-9'}>
                        <div className="row align-items-center">
                           <div className="col-auto">
                              <h2 className="mb-0 product-speed">
                                 {pkg.speed}
                              </h2>
                           </div>
                           <div className="col">
                              <p className="mb-0 product-unit">{pkg.unit}</p>
                              {pkg.unit_sub ? (
                                 <p className="mb-0 product-sub-unit">
                                    {pkg.unit_sub}
                                 </p>
                              ) : null}
                           </div>
                        </div>
                     </div>
                     {isArrowIcon && (
                        <div className="col-2 col-md-3 d-flex justify-content-end">
                           <ArrowUpRightIcon
                              strokeWidth={1.5}
                              viewBox="0 0 20 22"
                              className="text-dark-100"
                           />
                        </div>
                     )}
                  </div>

                  <div className="mt-3 card-footer bg-transparent p-0 border-top border-dark-400">
                     <div className="row align-items-center">
                        <div className="col-12 col-xl-7 mt-4">
                           {isTermAndCond && (
                              <p className="small mb-1 opacity-50 product-tc">
                                 ({localeDataPackage.termAndCond[router.locale]}
                                 )
                              </p>
                           )}
                           <p className="fs-24 fw-600 mb-0 product-price">
                              {pkg.price}
                              <span className="ms-1 fw-400 fs-12">
                                 {/* {pkg.period} */}/{' '}
                                 {localeDataPackage.period[router.locale]}
                              </span>
                           </p>
                        </div>
                        <div className="col-12 col-xl-5 mt-4">
                           <button className="btn btn-subscribe btn-dark-300 btn-sm w-100 d-flex justify-content-between align-items-center">
                              <span className="text-white fw-semibold mb-0">
                                 {localeDataPackage.subscribeNow[router.locale]}
                              </span>
                              <ArrowUpRightIcon
                                 strokeWidth={2}
                                 className="text-white"
                                 width={16}
                                 height={16}
                              />
                           </button>
                        </div>
                     </div>
                  </div>
                  {/*</div>*/}
               </SwiperSlide>
            )
         })}
      </Swiper>
   )
}

export const SlideProductDedicatedPoint = ({
   points = [],
   extraClass = '',
   // slidesPerView = 2,
   isBackgroundDark = false,
   config = {},
}) => {
   const router = useRouter()

   return (
      <Swiper
         // initialSlide={1}
         spaceBetween={20}
         pagination={{ clickable: true }}
         autoHeight={true}
         autoplay
         // slidesPerView={slidesPerView}
         speed={1000}
         loop={true}
         // centeredSlides={true}
         loopAdditionalSlides={100}
         modules={[Pagination]}
         className={'my-swiper-article ' + extraClass}
         mousewheel={true}
         {...config}>
         {points.map((point, index) => {
            return (
               <SwiperSlide
                  key={index}
                  className={
                     'card card-body bg-transparent border-0 cursor-pointer h-100 pe-4 ' +
                     (isBackgroundDark ? 'swiper-slide-dark' : '')
                  }>
                  <img
                     src={_convertCMSFile(point?.thumbnail?.url || '')}
                     alt="globalxtreme package lite"
                     className="icon-48 mb-4 mb-md-5"
                     width="auto"
                     height="auto"
                     loading="lazy"
                  />

                  <div className="card-footer border-0 p-0">
                     <h3 className="mb-4">{point.title}</h3>
                     <hr className="mt-0 mb-4" />
                     <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        className="mb-0">
                        {point.description}
                     </ReactMarkdown>
                  </div>
               </SwiperSlide>
            )
         })}
      </Swiper>
   )
}

export const SlideProductDedicatedInDetail = ({
   products = [],
   extraClass = '',
   config = {},
}) => {
   const router = useRouter()
   const useProduct = useHookProduct()

   return (
      <Swiper
         // initialSlide={1}
         spaceBetween={20}
         pagination={{ clickable: true }}
         autoHeight={true}
         autoplay
         speed={1000}
         loop={true}
         // centeredSlides={true}
         loopAdditionalSlides={100}
         modules={[Pagination]}
         className={'my-swiper-package ' + extraClass}
         mousewheel={true}
         {...config}>
         {products.map((product, index) => {
            const isThumbnail = product?.thumbnail?.url ? true : false
            const number = index + 1

            return (
               <SwiperSlide
                  key={index}
                  className={
                     'card-package position-relative d-flex overflow-hidden cursor-pointer ' +
                     (!isThumbnail ? 'bg-tint-600' : 'card-with-img')
                  }
                  onClick={() => {
                     useProduct.choose(
                        formatListWithNumberOrder(product, index)
                     )
                     router.push('/coverage-area')
                  }}>
                  {isThumbnail ? (
                     <>
                        <img
                           src={_convertCMSFile(product.thumbnail.url)}
                           alt="Packet Dedicated"
                           className="img-packet-dedicated"
                           loading="lazy"
                        />
                        <div className="img-linear-background"></div>
                     </>
                  ) : null}

                  <div
                     className={
                        'w-100 ' +
                        (isThumbnail
                           ? 'mt-auto text-light-300'
                           : 'text-dark-100 d-flex flex-column')
                     }>
                     <div className="w-100">
                        <h3
                           className={
                              'position-relative fw-500 dedicated-product-title ' +
                              (isThumbnail
                                 ? 'text-light-200 mb-2 mb-xl-3'
                                 : 'text-dark-200 mb-0')
                           }>
                           {product.title}{' '}
                           <sup className="ms-2 dedicated-product-number">
                              {number}
                           </sup>
                        </h3>
                     </div>
                     <div
                        className={
                           'row align-items-center position-relative ' +
                           (!isThumbnail ? 'mt-auto' : '')
                        }>
                        {!isThumbnail ? (
                           <div className="col-md-12">
                              <div className="border-bottom border-dark-200 mb-3"></div>
                           </div>
                        ) : null}
                        <div className="col-12 col-md-9">
                           <div className="row align-items-center">
                              <div className="col-auto">
                                 <h2
                                    className={
                                       'mb-0 dedicated-product-speed ' +
                                       (isThumbnail
                                          ? 'text-light-200'
                                          : 'text-dark-200')
                                    }>
                                    {product.speed}
                                 </h2>
                              </div>
                              <div className="col">
                                 <p className="dedicated-product-unit mb-0">
                                    {product.unit}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </SwiperSlide>
            )
         })}
      </Swiper>
   )
}
